<template>
  <div class="material_box">
    <div class="my_meterial">
      <el-row :gutter="20" style="height: 100%;">
        <el-col :span="16" style="height: 100%;padding-right:0;">
          <div class="left_content">
            <div class="top_search">
              <el-input placeholder="请输入素材名称或关键字" v-model="searchValue" @keyup.enter.native="searchMeterial" class="input-with-select">
                <el-select v-model="materialSelect" slot="prepend" placeholder="请选择" :popper-append-to-body="false" @change="changeMeterial">
                  <el-option label="视频" :value="1"></el-option>
                  <el-option label="图片" :value="2"></el-option>
                  <el-option label="音频" :value="3"></el-option>
                </el-select>
                <el-button slot="append" icon="el-icon-search" @click="searchMeterial"></el-button>
              </el-input>
              <el-checkbox v-model="filterChecked" style="margin-left: 92px;" @change="filterCheck" :disabled="meterialList.length?false:(filterChecked?false:true)" :class="meterialList.length?'':(filterChecked?'':'opacity')">仅显示未标签素材</el-checkbox>
            </div>
            <div class="middle_list" ref="middleListContent" v-loading="ifLoading" element-loading-background="rgba(0, 0, 0, 0.1)" element-loading-text="素材加载中...">
              <!-- 视频gif -->
               <div class="list img_list" v-if="materialSelect == 1" :style="meterialList.length?'padding-bottom:40px;':''">
                <div class="item img_item" v-for="(item, index) in meterialList" :key="index">
                  <div class="img_outer" @click="checkAndEdit(item.ip_id)" :id="item.ip_id" @mouseenter.stop.self="imgMouseenter" @mouseleave.stop.self="imgMouseleave" >
                    <img :src="item.gifNode" alt="" :class="['gifNode',item.gifNode ? 'gifNode_show':'']">
                    <img :src="item.cover_img" alt="" class="imgNode" v-if="item.showImg">
                    <i class="iconfont icon-shipin1" v-if="!item.cover_img"></i>
                    <i class="choose_material" v-if="item.ifChoose"></i>
                    <span class="video_duration" v-else>{{item.duration}}</span>
                    <div class="hover_effect">
                      <span class="iconfont icon-shanchujilu" @click.stop.self="deleteConfirm(item.ip_id)"></span>
                      <span class="iconfont icon-xiazai" @click.stop.self="videoDownloadConfirm(item.ip_id)"></span>
                      <span class="iconfont icon-tianjiazhiguankanliebiao" @click.stop.self="joinWatchList(item.ip_id)"></span>
                      <span :class="['iconfont',item.isStar ?'icon-shoucang':'icon-weishoucang']" @click.stop.self="ifStar(item)"></span>
                    </div>
                  </div>
                  <el-tooltip v-delTabIndex effect="dark" :content="item.name" :placement="!filterNum(index +1) && item.name.length > 18 ?'bottom-end':'bottom-start'" popper-class="my_material_tooltip">
                    <div class="one_line_ellipsis title" @click="goMaterialDetail(item.ip_id)">{{item.name}}</div>
                  </el-tooltip>

                </div>
              </div>

              <!-- 图片 -->
              <div class="list img_list" v-if="materialSelect == 2" :style="meterialList.length?'padding-bottom:40px;':''">
                <div class="item img_item" v-for="(item, index) in meterialList" :key="index">
                  <div class="img_outer" @click="checkAndEdit(item.ip_id)">
                    <img :src="item.cover" alt="">
                    <i class="choose_material" v-if="item.ifChoose"></i>
                    <div class="hover_effect">
                      <span class="iconfont icon-shanchujilu" @click.stop.self="deleteConfirm(item.ip_id)"></span>
                      <span class="iconfont icon-xiazai" @click.stop.self="downloadImg(item.url,item.name)"></span>
                      <span :class="['iconfont',item.isStar ?'icon-shoucang':'icon-weishoucang']" @click.stop.self="ifStar(item)"></span>
                    </div>
                  </div>
                  <el-tooltip v-delTabIndex effect="dark" :content="item.name" :placement="!filterNum(index +1) && item.name.length > 18 ?'bottom-end':'bottom-start'" popper-class="my_material_tooltip">
                    <div class="one_line_ellipsis title" @click="goMaterialDetail(item.ip_id)">{{item.name}}</div>
                  </el-tooltip>

                </div>
              </div>

              <!-- 音频 -->
              <div class="list audio_list" v-if="materialSelect == 3" :style="meterialList.length?'padding-bottom:40px;':''">
                <div class="item audio_item"  v-for="(item, index) in meterialList" :key="index">
                  <div class="audio_outer" @click="checkAndEdit(item.ip_id)">
                    <img src="../assets/images/headset.png" alt="">
                    <!-- 时间线容器 -->
                    <!-- <div id="timeline" ref="timeline" /> -->
                    <!-- 音频容器 -->
                    <div :id="'waveform' + item.ip_id"></div>
                    <span class="control_icon control_play" @click.self="audioPause(item)" v-if="item.isplay"></span>
                    <span class="control_icon control_pause" @click.self="audioPlay(item)" v-if="!item.isplay"></span>
                    <i class="choose_material"  v-if="item.ifChoose"></i>
                    <span class="video_duration" v-else>{{item.duration}}</span>
                    <div class="hover_effect">
                      <span class="iconfont icon-shanchujilu" @click.stop.self="deleteConfirm(item.ip_id)"></span>
                      <span class="iconfont icon-xiazai" @click.stop.self="downloadImg(item.url,item.name)"></span>
                      <span :class="['iconfont',item.isStar ?'icon-shoucang':'icon-weishoucang']" @click.stop.self="ifStar(item)"></span>
                    </div>
                  </div>
                  <el-tooltip v-delTabIndex effect="dark" :content="item.name" :placement="!filterNum(index +1) && item.name.length > 18 ?'bottom-end':'bottom-start'" popper-class="my_material_tooltip">
                    <div class="one_line_ellipsis title" @click="goMaterialDetail(item.ip_id)">{{item.name}}</div>
                  </el-tooltip>
                </div>
              </div>

              <noResult v-if="!ifSearch && !meterialList.length && !ifLoading" iconUrl="icon-meiyoujieguo" iconSty="font-size:50px" textSty="font-size:14px" noResultText="没有符合条件的素材"></noResult>
              <noResult v-if="ifSearch && !meterialList.length && !ifLoading" iconUrl="icon-meiyousucai" iconSty="font-size:50px" textSty="font-size:14px" noResultText="暂时没有素材"></noResult>
            </div>
            <div class="bottom_des">
              共{{totalCount}}个素材
            </div>
          </div>
        </el-col>
        <el-col :span="6" :offset="2" style="padding:0;">
          <div class="right_detail">
            <div class="title">
              编辑信息
            </div>
            <div class="edit_detail">
              <el-row type="flex" style="margin-bottom:10px;">
                <el-col :span="4">名称</el-col>
                <el-col :span="20">
                  <el-input
                    placeholder=""
                    v-model="materialName"
                    size="small"
                    :disabled="meterialList.length?false:true"
                    :class="meterialList.length?'':'opacity'"
                    :maxlength="40"
                    >
                  </el-input>
                </el-col>
              </el-row>
              <el-row type="flex" style="margin-bottom:10px;">
                <el-col :span="4">简介</el-col>
                <el-col :span="20" class="intro_sty">
                  <el-input
                    placeholder="简介最多输入200个字哦~"
                    v-model="materialIntro"
                    type="textarea"
                    :rows="3"
                    :maxlength="200"
                    :disabled="meterialList.length?false:true"
                    :class="['text_area_sty',meterialList.length?'':'opacity']"
                    >
                  </el-input>
                </el-col>
              </el-row>
              <el-row type="flex" style="margin-bottom:10px;">
                <el-col :span="4">标签</el-col>
                <el-col :span="20">
                  <div class="tag_box" @click="meterialList.length ? handleEditDialog(): '' " :disabled="meterialList.length?false:true" :class="meterialList.length?'':'opacity'" :style="meterialList.length?'':'cursor:not-allowed;cursor:no-drop;'">
                    <div style="color:rgba(255,255,255,0.8);" v-if="!tagList.length">点击添加标签</div>
                    <el-tag
                      v-for="tag in tagList"
                      :key="tag.tag_id"
                      closable
                      :disable-transitions="false"
                      @close="handleClose(tag.tag_id)"
                      >
                      {{tag.tag_name}}
                    </el-tag>
                  </div>
                </el-col>
              </el-row>
              <el-row type="flex" style="margin-bottom:10px;">
                <el-col :span="4">关键词</el-col>
                <el-col :span="20">
                  <el-input
                    placeholder="多个关键词用“、”隔开"
                    v-model="materialKeywords"
                    type="textarea"
                    :rows="5"
                    class="text_area_sty"
                    :disabled="meterialList.length?false:true"
                    :class="meterialList.length?'':'opacity'"
                    >
                  </el-input>
                </el-col>
              </el-row>
              <el-row type="flex" style="margin-bottom:10px;">
                <el-col :span="4">商用授权</el-col>
                <el-col :span="20">
                  <el-input
                    placeholder=""
                    v-model="commercialLicenses"
                    type="textarea"
                    :rows="5"
                    class="text_area_sty"
                    :disabled="meterialList.length?false:true"
                    :class="meterialList.length?'':'opacity'"
                    >
                  </el-input>
                </el-col>
              </el-row>
              <el-row type="flex" style="margin-bottom:10px;">
                <el-col :span="4">{{materialSelect == 3 ?'音乐人':'拍摄者'}}</el-col>
                <el-col :span="20">
                  <el-input
                    :placeholder="materialSelect == 3 ?'多个音乐人用“、”隔开':'多个拍摄者用“、”隔开'"
                    v-model="photographer"
                    size="small"
                    :disabled="meterialList.length?false:true"
                    :class="meterialList.length?'':'opacity'"
                    >
                  </el-input>
                </el-col>
              </el-row>
              <el-row type="flex" style="margin-bottom:2.2vh;">
                <el-col :span="4">谁可以看</el-col>
                <el-col :span="20">
                  <el-select v-model="viewTypeValue" placeholder="请选择" size="mini" :popper-append-to-body="false" @change="setPermissions" class="ifsee_select" :disabled="meterialList.length?false:true" :class="meterialList.length?'':'opacity'">
                    <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      @click.native="selectPermission(item)"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </el-col>
              </el-row>
              <el-row type="flex" class="allow_change">
                <el-col :span="4"></el-col>
                <el-col :span="20">
                  <el-checkbox v-model="allowChange" :disabled="meterialList.length?false:true" :class="meterialList.length?'':'opacity'">允许其他用户修改标签</el-checkbox>
                </el-col>
              </el-row>
              <el-row type="flex">
                <el-col :span="4"></el-col>
                <el-col :span="20" style="text-align:right;" class="operation_btn">
                  <el-button size="small" @click="clearAll" :disabled="meterialList.length?false:true" :class="meterialList.length?'':'opacity'">清 空</el-button>
                  <el-button size="small" style="margin-left:20px;" @click="editMeterialDetail" :disabled="meterialList.length?false:true" :class="meterialList.length?'':'opacity'">保 存</el-button>
                  <div class="success_info" v-if="saveMeterial">素材信息保存成功</div>
                </el-col>
              </el-row>
            </div>
          </div>
        </el-col>
      </el-row>

      <el-dialog
        title="下载"
        :visible.sync="downloadDialog"
        width="461px"
        top="30vh"
        custom-class="material-dialog"
        :show-close="true"
        :before-close="downloadClose">
        <span style="display:block;margin-bottom:21px;color:rgba(255,255,255,0.8)">分辨率：</span>
        <div class="radio_check">
          <el-radio label="标清" v-if="downloadResolution.length == 1" class="disable_radio">标清</el-radio>
          <template v-for="(item,i) in downloadResolution">
            <el-radio  v-model="system" :label="item.resolution" :key="i">{{item.resolution}}</el-radio>
          </template>
        </div>

        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="cnfirmDownloadMeterial">确 定</el-button>
          <el-button @click="downloadDialog = false" style="margin-right:30px;">取 消</el-button>
        </span>
      </el-dialog>
      <el-dialog
          :title="viewTypeValue == '3'?'部分可见':'不给谁看'"
          :visible.sync="setPermissionsDialog"
          width="382px"
          top="30vh"
          :close-on-click-modal="false"
          :close-on-press-escape="false"
          custom-class="permission-dialog"
          :show-close="true"
          :before-close="setPermissionsClose">
          <div class="search_tree">
              <el-input
                placeholder="输入关键字"
                suffix-icon="el-icon-search"
                size="mini"
                v-model="filterText"
                v-focus
                >
              </el-input>
              <el-tree
                class="filter-tree"
                :data="dataSee"
                show-checkbox
                default-expand-all
                node-key="id"
                ref="tree"
                highlight-current
                :props="defaultProps"
                @check="checkTree"
                :filter-node-method="filterNode">
              </el-tree>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="ensurePermission">确 定</el-button>
            <el-button @click="cancelPermission">取 消</el-button>
          </span>
      </el-dialog>
      <el-dialog
          title="标签编辑"
          :visible.sync="showEditDialog"
          width="461px"
          top="30vh"
          custom-class="edit-dialog"
          :show-close="true"
          :before-close="closeEditDialog">
          <div class="search_tag">
            <div class="top_search">
              <el-autocomplete
                popper-class="search-autocpmplete"
                v-model="tagValue"
                :trigger-on-focus="false"
                :fetch-suggestions="querySearchAsync"
                placeholder="请输入标签"
                size="small"
                @select="handleSelect"
                :popper-append-to-body="true"
                :debounce="10"
                ref="autoTag"
                v-focus
                @focus="focusEdit"
              >
                <template slot-scope="{ item }">
                  <div class="search_tag">{{ item.value }}</div>
                </template>
              </el-autocomplete>
              <div class="edit_btn" @click="addTag">添加</div>
            </div>
          </div>
      </el-dialog>
      <el-dialog
          title="提 示"
          :visible.sync="warningDialog"
          width="400px"
          top="30vh"
          custom-class="permission-dialog"
          :show-close="true"
          :before-close="warningClose">
          <div class="warning_content">
            <div>
              您确定要把素材放入回收站吗？
            </div>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="deleteMeterial">是</el-button>
            <el-button @click="warningDialog = false">否</el-button>
          </span>
      </el-dialog>

      <el-dialog
          title="提 示"
          :visible.sync="saveMeterialDialog"
          width="400px"
          top="30vh"
          custom-class="permission-dialog"
          :show-close="false"
          :close-on-click-modal="false"
          :close-on-press-escape="false"
          >
          <div class="warning_content">
            <div>
              已编辑素材信息未保存，是否保存？
            </div>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="saveMeterialInfo">是</el-button>
            <el-button @click="donNotSaveEdit">否</el-button>
          </span>
      </el-dialog>
    </div>

  </div>
</template>
<script>
// import axios from 'axios';
import {compareArraySort,preventSpaceScroll} from '../plugins/solvemethod'
import $ from 'jquery'
import noResult from '../component/noResult.vue'

import WaveSurfer from 'wavesurfer.js'
// import Timeline from 'wavesurfer.js/dist/plugin/wavesurfer.timeline'//Timeline插件
// import Region from 'wavesurfer.js/dist/plugin/wavesurfer.regions'//regions插件
export default {
  inject:['reload'],
  components: {
      noResult,
  },
  data() {
    return {
      searchValue: '',
      materialSelect: 1,
      filterChecked: false,
      playerList: [],
      downloadDialog: false,
      system: '',
      meterialList: [],
      materialName: '', //素材名称
      materialIntro: '', //素材简介
      materialKeywords: '', //素材关键词
      dynamicTagsId: [], //素材标签的id
      tagList: [], //素材标签（包含名称和id）
      commercialLicenses: '', //商业授权
      photographer: '', //拍摄者
      allowChange: false, //是否允许其他用户修改标签
      options: [{
          value: '1',
          label: '公开'
        }, {
          value: '2',
          label: '仅自己可见'
        }, {
          value: '3',
          label: '部分可见'
        }, {
          value: '4',
          label: '不给谁看'
      }],
      viewTypeValue: '1',
      viewTypeOld:'',
      setPermissionsDialog: false,
      showEditDialog: false,
      warningDialog: false,
      filterText: '',
      dataSee: [],
      departmentSeeIds:[],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      // items:['标签一', '标签二', '标签三'],
      tagResultList: [],
      tagValue: '',
      timeout:  null,
      pageCount: 1, //页码
      totalCount: 0, //数据总条数
      limitCount: 40, //每页条数
      ipId:'', //素材的id
      downloadResolution: [], //下载的分辨率等
      meterialDetail:{}, //素材详情
      saveMeterial: false, //保存素材信息成功提示
      saveMeterialDialog:false,
      userBehavior: '', //判断是 1切换素材 2搜索 3仅显示未标签素材 4离开页面 5.切换素材种类的时候 6. 删除其它素材时候
      routeLeave: false, //页面离开
      toRoute: '', //要去的页面
      ifSearch: false, //如果是全部素材为空，显示一种空状态，如果搜索之后为空显示另一种空状态
      ifLoading:true, //控制loading圈是否显示

      wavesurfer: '',
    }
  },
  directives: {
	    focus: {
	      inserted: function(el) {
	        el.querySelector("input").focus();
	      }
	    }
  },
  computed: {
    myMaterialPage:{
      get() {
        return this.$store.state.myMaterial
      },
      set(val){
          // this.$store.commit('myMaterialPage', val)
      }
    }
  },
  watch: {
    myMaterialPage(val) {
      if(val == true){
        this.reload();
      }
    },
    filterText(val) {
      this.$refs.tree.filter(val);
    },
    viewTypeValue(newVal,oldVal) {
      this.viewTypeOld = oldVal
    },
    $route(to,from){
      // console.log(to,from)
      this.meterialList.length && Object.keys(this.meterialDetail).length!=0 && this.checkIfEdit()?this.saveMeterialDialog = true:''
    },
    materialSelect() {
        this.$nextTick(()=>{
          $(".img_outer").each((i,item)=>{
            let video_boxW = $(item).width()
            $(item).css("height",video_boxW * 0.587 + "px")
          })
        })
    },

  },
  created() {
    this.$store.commit('myMaterialPage', false)
    sessionStorage.removeItem("checkId") //将素材id缓存清除
    this.getMeterialList()
  },
  mounted () {
    //翻页
    let scrollContent = this.$refs.middleListContent
    scrollContent.onscroll = this.throttle(() => {
      this.scrollBottom(this.pageCount, this.limitCount, this.totalCount)
    }, 2000) //滑动则触发事件,2000毫秒响应一次


    window.onresize = () => {
      $(".img_outer").each((i,item)=>{
        let video_boxW = $(item).width()
        $(item).css("height",video_boxW * 0.587 + "px")
      })
    };
    preventSpaceScroll($('.middle_list'))
  },
  methods: {
    audioPlay(item) {
      // console.log("播放")
      this.meterialList.forEach((item,i)=>{
        item.wavesurfer.pause()
        item.isplay = false
      })
      item.isplay = true
      item.wavesurfer.play()
    },
    audioPause(item) {
      // console.log("暂停")
      item.isplay = false
      item.wavesurfer.pause()
    },

    filterNum (n) {
      if (n % 4 == 1 || n % 4 == 2) {
        return true
      }else{
        return false
      }
    },
    // testMouseLeave() {
    //   console.log('111leave')
    //   this.meterialList.forEach((item,i)=>{
    //     item.showImg = true
    //     item.gifNode = ''
    //   })
    // },
    // testMouseEnver() {
    //   // console.log('222enter')
    //   // this.meterialList.forEach((item,i)=>{
    //   //   item.showImg = true
    //   //   item.gifNode = ''
    //   // })
    // },
    imgMouseenter(e) {
      // console.log('enter')
      this.meterialList.forEach((item,i)=>{
      item.gifNode = ''
      if(e.target.id == item.ip_id){
        item.gifNode = item.cover
          item.showImg = false
      }
      })
    },
    imgMouseleave(e) {
      // console.log('leave')
      this.meterialList.forEach((item,i)=>{
        item.showImg = true
        item.gifNode = ''
      })
    },
     handleClose(id) {
        this.tagList.forEach((item,i)=>{
          if(item.tag_id == id){
            this.tagList.splice(i,1)
            this.dynamicTagsId.splice(i,1)
          }
        })
      },
    //跳转至素材详情
    goMaterialDetail(id) {
      let materialType = ''
      switch (this.materialSelect) {
        case 1:
          materialType = 'video'
          break;
        case 2:
          materialType = 'img'
          break;
        case 3:
          materialType = 'audio'
          break;
      }
      let detailData = this.$router.resolve({
        path: "/material_detail",
        query:{ip_id:id,materialType: materialType}
      });
      window.open(detailData.href, '_blank');
    },
    videoDownloadConfirm(id) {
      this.getDownloadUrl(id)
      this.downloadDialog = true
    },
    deleteConfirm(id) {
      this.userBehavior = 6
      this.ipId = id
      if(this.checkIfEdit() == true) {
        this.saveMeterialDialog = true
      }else{
        this.warningDialog = true
      }
    },

    //删除我的素材
    deleteMeterial() {
      this.meterialList = []
      const formData = new FormData()
      formData.append('ip_id', this.ipId)
      this.$http({
        method: 'POST',
        url: '/vw_material/del_my_material/',
        data: formData
      }).then(res => {
        if (res.data.status === 0) {
          this.warningDialog = false
          this.$store.commit("deleteItem", true);
           setTimeout(() => {
            this.$store.commit('deleteItem',false)
          }, 100);
          sessionStorage.removeItem("checkId") //将素材id缓存清除
          this.getMeterialList()
        } else {
          this.$message.error(res.data.msg)
        }
      }).catch(error => console.log(error))
    },


    downloadClose(done) {
      this.downloadDialog = false
    },
    selectPermission (item) {
      console.log(item)
      this.departmentSeeIds = []
      this.$refs.tree ? this.$refs.tree.setCheckedKeys([]):'';
      console.log(item.value)
      if(item.value == '3' || item.value == '4'){
        this.setPermissionsDialog = true
      }
    },
    setPermissionsClose() {
      this.setPermissionsDialog = false
    },
    warningClose() {
      this.warningDialog = false
    },

    //收藏、取消收藏
    ifStar(item) {
      let formData = new FormData();
      formData.append("ip_id", item.ip_id);
      formData.append("type", this.materialSelect);
      this.$http({
        method: "POST",
        url: "/vw_material/add_my_collect/",
        data: formData,
      }).then((res) => {
       if (res.data.status === 0) {
         item.isStar = !item.isStar
        } else {
          this.$message.error(res.data.msg)
        }
      }).catch(error => console.log(error))
    },

    //加入观看列表
    joinWatchList(id) {
      let formData = new FormData();
      formData.append("ip_id", id);
      this.$http({
        method: "POST",
        url: "/vw_material/add_my_watch_record/",
        data: formData,
      }).then((res) => {
       if (res.data.status === 0) {
          this.$store.commit('editAdd',true)
          setTimeout(() => {
            this.$store.commit('editAdd',false)
          }, 1000);
        } else {
          this.$message.error(res.data.msg)
        }
      }).catch(error => console.log(error))
    },

    //下载获取分辨率及对应url
    getDownloadUrl(id) {
      let formData = new FormData();
      formData.append("ip_id", id);
      this.$http({
        method: "POST",
        url: "/upload/get_download_res/",
        data: formData,
      }).then((res) => {
       if (res.data.status === 0) {
         this.downloadResolution = res.data.result
         this.system = res.data.result[0].resolution
        } else {
          this.$message.error(res.data.msg)
        }
      }).catch(error => console.log(error))
    },

    //确定下载素材
    cnfirmDownloadMeterial() {
      let url = ''
      let download_name = ''
      this.downloadDialog = false
      this.downloadResolution.forEach((item,i)=>{
        item.resolution == this.system ? url = item.url:''
        item.resolution == this.system ? download_name = item.download_name:''
      })
      let link = document.createElement('a');
      link.download = download_name  // 下载文件的名字
      // console.log(download_name)
      link.href = url  //链接
      document.body.appendChild(link) //a标签加到body
      link.click(); //模拟a标签点击
      document.body.removeChild(link) // 下载完成移除元素
    },
    // 下载图片 传入下载地址
    downloadImg(imgsrc, name) {
      let download_name = ''
      let link = document.createElement('a');
      link.download = download_name  // 下载文件的名字
      // console.log(download_name)
      link.href = imgsrc  //链接
      document.body.appendChild(link) //a标签加到body
      link.click(); //模拟a标签点击
      document.body.removeChild(link) // 下载完成移除元素
    },
    setPermissions(value) {
      // console.log(this.viewTypeValue)
      // console.log(value)
      this.departmentSeeIds = []
      if(value == '3' || value == '4'){
        // this.resetChecked()
        this.$refs.tree?this.$refs.tree.setCheckedKeys([]):'';
        this.setPermissionsDialog = true
      }
    },
    ensurePermission() {
      this.setPermissionsDialog = false
    },
    cancelPermission() {
      this.setPermissionsDialog = false
      this.viewTypeValue = this.viewTypeOld //(如果点了弹框的取消的话,还是保留在上一个状态)
      this.filterText = ''
      this.$refs.tree ? this.$refs.tree.setCheckedKeys([]):'';
    },

    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    checkTree(now,all) {
      // console.log(now,all)
      this.departmentSeeIds = all.checkedKeys
      all.checkedKeys?all.checkedNodes.forEach((item1,j)=>{
        JSON.stringify(item1.children)?this.departmentSeeIds.splice(j,1):''
      }):''

      // console.log(this.departmentSeeIds)
    },
    handleEditDialog() {
      // if($(".search-autocpmplete").length){
      //   console.log($(".search-autocpmplete")[0])
      //   let popSearch = $(".search-autocpmplete")[0]
      //   $(popSearch).find(".el-scrollbar").css("display",'none !important')
      //   $(popSearch).hide()
      // }
      // console.log(this.tagResultList)
      this.tagResultList = []
      this.tagValue = ''
      this.showEditDialog = true
      this.showEditDialog && this.$nextTick(()=>{
        this.$refs.autoTag.focus()
      })
    },
    closeEditDialog() {
      this.showEditDialog = false
    },
    querySearchAsync(queryString, cb) {
      this.tagResultList = []
      var tagResultList = this.tagResultList
      this.$http({
        method: "GET",
        url: "/tag/get_tag_list/",
        params: {
          search_name: this.tagValue
        }
      }).then((res) => {
       if (res.data.status === 0) {
        //  console.log(Object.values(res.data.data))
         Object.values(res.data.data).forEach((item,i)=>{
           item.forEach((item1,j)=>{
             var obj = {}
             obj.value = item1.tag_name
             obj.tag_id = item1.id
             tagResultList.push(obj)
           })

         })
         var results = tagResultList
          var showSuggestion = document.getElementsByClassName("search-autocpmplete")[0]
         cb(results);
         if(results.length){
          showSuggestion.style.display = 'block'
         }
        } else {
          this.$message.error(res.data.msg)
        }
      }).catch(error => console.log(error))
    },
    focusEdit(){
      this.tagResultList = []
    },

    handleSelect(item) {
      // console.log(item);
    },

    searchMeterial() {
      this.userBehavior = 2
      sessionStorage.removeItem("checkId") //将素材id缓存清除
      if(this.searchValue.trim() == ''){
        this.$message({
          showClose: true,
          message: '请输入素材名称或关键字',
          type: 'error'
        });
      }else{
        if(this.checkIfEdit() == true && this.meterialList.length){
          this.saveMeterialDialog = true
        }else{
          this.clearAll()
          this.getMeterialList()
        }
      }
    },

    filterCheck() {
      this.userBehavior = 3
      sessionStorage.removeItem("checkId") //将素材id缓存清除
      if(this.checkIfEdit() == true && this.meterialList.length){
        this.saveMeterialDialog = true
      }else{
        this.clearAll()
        this.getMeterialList()
      }
    },

    setAudio(list) {
      // console.log(list)
      for(var i = 0; i < list.length; i++) {
        list[i].wavesurfer = WaveSurfer.create({
          container: `#waveform${list[i].ip_id}`,//绑定容器，第一种方法
          // forceDecode: true,
          backend: 'MediaElement'
        })
        list[i].wavesurfer.load(list[i].short_video ? list[i].short_video : list[i].url,[],'metadata')//加载音频
        let j = i
        list[i].wavesurfer.on('finish', function () {
          list[j].wavesurfer.stop(); //回到开始并停止
          list[j].isplay = false;
        });

      }
    },
    //获取所有素材列表
    getMeterialList() {
      this.clearAll()
      this.meterialList = []
      this.ifLoading = true

      this.pageCount = 1
      this.totalCount = 0
      this.$nextTick(()=>{
        this.$refs.middleListContent.scrollTop=0
      })

      let filterChecked = ""
      this.filterChecked == false ? filterChecked = 0 : filterChecked = 1
      this.searchValue == "" && filterChecked == 0 ? this.ifSearch = true: this.ifSearch = false

      this.$http({
        method: 'GET',
        url: '/vw_material/my_material/',
        params: {
          type: this.materialSelect,  //类型，1为视频，2为图片,3为音频
          search_name: this.searchValue, //搜索词
          no_tag_if: filterChecked,  //是否勾选只看没标签素材，1为勾选，0为未勾选
          page: this.pageCount,  //页码，从1,开始
          page_num: this.limitCount  //每页数量
        }
      }).then(res => {
        if (res.data.status === 0) {
          this.ifLoading = false
          res.data.result.forEach((item,i)=>{
            item.isStar = item.collect_if
            item.isplay = false
            item.ifChoose = false
            item.gifNode = ''
            item.showImg = true
          })
          this.meterialList = res.data.result
          this.$nextTick(()=>{
           this.materialSelect == 3 && this.setAudio(res.data.result)
          })
          this.pageCount += 1
          this.totalCount = res.data.total //总条数
          this.initPage(res.data.result)
        } else {
          this.$message.error(res.data.msg);
        }
      }).catch(error => console.log(error))
    },


    //选中某个视频/图片事件
    checkAndEdit(ip_id) {
      //meterialDetail(后台最新详情)与本地输入框做对比 ----------------------------------------
      this.userBehavior = 1
      this.ipId = ip_id
      sessionStorage.setItem("checkId",ip_id) //将素材id存储
      //在选中某个素材前，判断是否编辑过右侧详情，如果有任意一项编辑过需要给提示是否保存
      // console.log(this.meterialDetail)
      if(this.checkIfEdit() == true && Object.keys(this.meterialDetail).length!=0) {
        this.saveMeterialDialog = true
      }else{
          this.clearAll()
          this.meterialList.forEach((item,i)=>{
              item.ip_id == this.ipId ? item.ifChoose = true: item.ifChoose = false
          })
          this.departmentSeeIds = []
          this.dataSee = []
          this.tagList = []
          this.dynamicTagsId = []
          // console.log(this.meterialDetail)
          this.getMeterialDetail(this.ipId)
      }
      // ----------------------------------------

    },

    //切换视频、图片、音频
    changeMeterial(value) {
      // console.log(value)
      this.userBehavior = 5
      sessionStorage.removeItem("checkId") //将素材id缓存清除
      this.searchValue = ''
      if(this.checkIfEdit() == true && this.meterialList.length) {
        this.saveMeterialDialog = true
      }else{
        this.getMeterialList()
      }
    },

    //获取素材详情
    getMeterialDetail(id) {
      this.meterialDetail = {}
      this.$http({
        method: "GET",
        url: "/vw_material/get_ip_info/",
        params: {
          ip_id: id
        }
      }).then((res) => {
       if (res.data.status === 0) {
          this.meterialDetail = res.data.result
          this.setBaseInfo(res.data.result)
        } else {
          this.$message.error(res.data.msg)
        }
      }).catch(error => console.log(error))
    },

    //修改素材详情
    editMeterialDetail(para) {
      const formData = new FormData();
      formData.append('ip_id', this.meterialDetail.ip_id); //素材id
      formData.append('name', this.materialName); //素材名
      formData.append('introduce', this.materialIntro); //素材简介
      formData.append('tag_ids', this.dynamicTagsId?this.dynamicTagsId.join(","):''); //素材标签
      formData.append('keywords', this.materialKeywords.replace(/、/g,",")); //素材关键词
      formData.append('commercial_licenses', this.commercialLicenses); //商业授权
      formData.append('photographers', this.photographer.replace(/、/g,",")); //拍摄者
      formData.append('view_type', this.viewTypeValue); //浏览权限
      formData.append('user_ids', this.departmentSeeIds); //浏览权限 - 部门id集合
      formData.append('allow_change_if', this.allowChange == false ?0:1); //是否允许其他人修改
      this.$http({
        method: "POST",
        url: "/vw_material/update_ipinfo/",
        data: formData
      }).then((res) => {
       if (res.data.status === 0) {
        //  通知观看列表刷新
        this.$store.commit("deleteItem", true);
           setTimeout(() => {
            this.$store.commit('deleteItem',false)
          }, 100);
        //  sessionStorage.removeItem("checkId") //将素材id缓存清除
         if(para == 'dialogSave'){
           this.$message({
              message: '素材信息保存成功',
              type: 'success'
            });
         }else{
           this.saveMeterial = true
           setTimeout(()=>{
            this.saveMeterial = false
           },3000)
         }
        //  sessionStorage.setItem("checkId",this.meterialDetail.ip_id) //将素材id存储
        if(this.toRoute == ''){
          this.getMeterialList()
        }

        } else {
          this.$message.error(res.data.msg)
        }
      }).catch(error => console.log(error))
    },

    //右侧素材详情初始化
    setBaseInfo(result) {
      this.materialName = result.name //素材名称
      this.materialIntro = result.introduce //素材简介

      this.dynamicTagsId = []
      this.departmentSeeIds = []
      this.dataSee = []
      result.tag_list.forEach((item)=>{
        this.dynamicTagsId.push(item.tag_id)
      })
      this.tagList = Object.assign([],result.tag_list) //深拷贝数组才不会影响result
      if(result.keywords !== "null" && result.keywords !== null && result.keywords !== undefined){
        this.materialKeywords = result.keywords.replace(/,/g,"、")//素材关键词
      }
      if(result.commercial_licenses !== 'null' && result.commercial_licenses !== null && result.commercial_licenses !== undefined){
          this.commercialLicenses = result.commercial_licenses //商业授权
      }
      this.photographer = result.photographer.replace(/,/g,"、") //拍摄者
      result.allow_change_if == 0 ? this.allowChange = false : this.allowChange = true  //allow_change 是否允许其他用户修改标签
      this.viewTypeValue = String(result.view_type)

      Object.keys(result.user_select_dict).forEach((key,i)=>{
        let obj = {}
        let children = []

        result.user_select_dict[key].length ? result.user_select_dict[key].forEach((item1,j)=>{
          let obj1 = {}
          obj1.id = item1.id
          obj1.label = item1.name
          children.push(obj1)
          item1.is_select == 1 ? this.departmentSeeIds.push(item1.id): ''
        }):''

        obj.children = children
        obj.id = i+1
        obj.label = key
        obj.children.length == 0 ? obj.disabled = true:''
        this.dataSee.push(obj)
      })
    },

    //清空所有input框
    clearAll() {
      this.materialName = ''
      this.materialIntro = ''
      this.tagList = []
      this.dynamicTagsId = []
      this.materialKeywords = ''
      this.commercialLicenses = ''
      this.photographer = ''
      this.viewTypeValue = '1'
      this.allowChange = false
      this.dataSee = []
      this.departmentSeeIds = []
      this.meterialDetail = {}
    },

    //标签添加
    addTag() {
      // console.log(this.dynamicTagsId)
      if(this.tagValue.trim() == ''){
        this.$message.error('请输入素材名称或关键字')
      }else{
        // console.log(this.tagResultList)
        if(!this.tagResultList.length){  //如果搜索结果为空，则调用添加标签接口
          this.getNewTagId()
        }else{ //如果搜索结果不为空，
          let obj = {}
          let resultMatch = false
          this.tagResultList.forEach((item,i)=>{
            if(item.value == this.tagValue){
              this.resultMatch = true  //如果搜索的结果某个恰好匹配输入框的值
              if(this.dynamicTagsId.indexOf(item.tag_id) > -1){
                  this.$message({
                    showClose: true,
                    message: '标签已添加过，请勿重复添加！',
                    type: 'error'
                  });
                  return false
              }
              obj = {
                tag_name: this.tagValue,
                tag_id: item.tag_id
              }
              this.dynamicTagsId.push(item.tag_id)
              // console.log(obj)
              this.tagList.push(obj)
              this.tagValue = ''
            }
          })
          resultMatch == false ? this.getNewTagId():''  //如果 虽然有搜索结果 但是输入框的值与结果都不匹配 ，还是需要调用添加标签接口
        }
      }
    },

    //新标签添加请求
    getNewTagId() {
      const formData = new FormData()
      formData.append("tag_name",this.tagValue.replace(/(^\s*)|(\s*$)/g, ""))
      this.$http({
        method: 'POST',
        url: '/tag/add_tag/',
        data: formData
      }).then(res => {
        if (res.data.status === 0) {
          let obj = {
            tag_id:res.data.tag_id,
            tag_name:this.tagValue
          }
          this.tagList.push(obj)
          this.tagValue = ""
          this.dynamicTagsId.push(res.data.tag_id)
          // console.log(this.dynamicTagsId)
        } else {
            // this.$message.error(res.data.msg)
        }
      }).catch(error => console.log(error))
      // this.$refs.saveTagInput.focus();
    },


    scrollBottom(pageC, limitC, totalCount) {
      let bottomOfWindow = document.documentElement.offsetHeight - document.documentElement.scrollTop - window.innerHeight <= 100   //距离底部200px时则开始触发函数
      if (
        bottomOfWindow &&
        (pageC - 1) * limitC < totalCount
        //如果此时到了滑动底部&&底部显示正在加载&&请求过的总数据小于后台总数据
      ) {
        this.pageCount += 1 //页码加一,为了保证取到下次数据
        let filterChecked = ""
        this.filterChecked == false ? filterChecked = 0 : filterChecked = 1
        this.$http({
        method: 'GET',
        url: '/vw_material/my_material/',
        params: {
          type: this.materialSelect,  //类型，1为视频，2为图片
          search_name: this.searchValue, //搜索词
          no_tag_if: filterChecked,  //是否勾选只看没标签素材，1为勾选，0为未勾选
          page: pageC,  //页码，从1,开始
          page_num: limitC  //每页数量
        }
      }).then(res => {
          let temp = this.meterialList
          let len = res.data.result.length
          if (len === 0) {
          }else if (res.data.status === 0 && temp !== undefined) {
            res.data.result.forEach((item,i)=>{
              item.isStar = item.collect_if
              item.isplay = false
              item.ifChoose = false
              item.gifNode = ''
              item.showImg = true
           })
            let arrT = res.data.result
            this.$nextTick(()=>{
              setTimeout(()=>{
                this.materialSelect == 3 && this.setAudio(res.data.result)
              },3000)
            })
            temp.push.apply(temp, arrT)

            this.initPage(this.meterialList)
                        //动态添加gif，为了及时暂停它

            // this.materialSelect == 1 ? this.$nextTick(() => { this.setVideo(); }): ''
        } else {
        }
      }).catch(error => console.log(error))
      }
    },

     //公用定时器 , 用于函数节流，防止多次触发监听
    throttle(fn, delay) {
      let timer = null
      let firstTime = true
      // console.log('!!1')
      return function(...args) {
        if (firstTime) {
          // 第一次加载
          fn.apply(this, args)
          return (firstTime = false)
        }
        if (timer) {
          // 定时器正在执行中，跳过
          return
        }
        timer = setTimeout(() => {
          clearTimeout(timer)
          timer = null
          fn.apply(this, args)
        }, delay)
      }
    },

    //初始化页面默认选中
    initPage(list) {
    //默认选中设置
      let checkId = sessionStorage.getItem("checkId")
      if(checkId){
      //  console.log(checkId)
       list.length && list.forEach((item,i)=>{
            item.ip_id == checkId ? item.ifChoose = true: item.ifChoose = false
        })
        this.getMeterialDetail(checkId)
      }else{
        // console.log(list[0])
        if(list.length){
          list[0].ifChoose = true
          this.getMeterialDetail(list[0].ip_id)
        }

      }
    },

    saveMeterialInfo() {
      this.saveMeterialDialog = false
      if(this.materialName == ''){
        this.$message({
          showClose: true,
          message: '素材名称不能为空！',
          type: 'error'
        });
        if(this.userBehavior == 3){
          this.filterChecked = !this.filterChecked
        }
        if(this.userBehavior == 5){
          this.materialSelect ==1 ? this.materialSelect = 2 :this.materialSelect = 1
        }
        return false
      }
      this.editMeterialDetail('dialogSave')
      if(this.userBehavior == 4){
        this.routeLeave = true
        this.$router.push({path:this.toRoute})
      }
    },
    donNotSaveEdit() {
      this.saveMeterialDialog = false
      // console.log(this.userBehavior)
      switch(this.userBehavior) {
        case 1: //切换素材
          this.meterialList.forEach((item,i)=>{
              item.ip_id == this.ipId ? item.ifChoose = true: item.ifChoose = false
          })
          this.departmentSeeIds = []
          this.dataSee = []
          this.tagList = []
          this.dynamicTagsId = []
          this.getMeterialDetail(this.ipId)
          break;
        case 2: //搜索
          this.clearAll()
          this.getMeterialList()
          break;
        case 3: //仅显示未标签素材
          this.clearAll()
          this.getMeterialList()
          break;
        case 4: //切换其它页面时候
          this.routeLeave = true
          this.$router.push({path:this.toRoute})
          break;
        case 5: //素材：图片和视频切换
          this.getMeterialList()
          break;
        case 6:  //删除素材时
          this.warningDialog = true
          break;
        default:
          break;
      }

    },
    // 检测右侧详情是否被编辑过
    checkIfEdit() {
       //meterialDetail(后台最新详情)与本地输入框做对比

      // md.name != this.materialName //素材名称
      // || md.introduce != this.materialIntro //素材简介
      // || dynamicTagsId != this.dynamicTagsId  //素材标签
      // || md.keywords != this.materialKeywords.replace(/、/g,",") //素材关键词
      // || md.commercial_licenses != this.commercialLicenses //素材商业授权
      // || md.photographer != this.photographer.replace(/、/g,",") //素材拍摄者
      // || String(md.view_type) != this.viewTypeValue //素材浏览权限
      // || departmentSeeIds != this.departmentSeeIds //素材部门人员id
      // || md.allow_change_if != this.allowChange == false ?0:1 //是否允许其他人修改
      let md = Object.assign({},this.meterialDetail) //做深拷贝处理
      let dynamicTagsId = []
      Object.keys(md).length!=0 && md.tag_list && md.tag_list.forEach((item)=>{ //标签
        dynamicTagsId.push(item.tag_id)
      })

      let departmentSeeIds = []
      Object.keys(md).length!=0 && md.user_select_dict && Object.keys(md.user_select_dict).forEach((key)=>{   //部门人员
        md.user_select_dict[key].length ? md.user_select_dict[key].forEach((item1)=>{
          item1.is_select == 1 ? departmentSeeIds.push(item1.id): ''
        }):''
      })

      if(md.commercial_licenses == 'null' || md.commercial_licenses == null || md.commercial_licenses === undefined){ //素材商业授权
        md.commercial_licenses = ''
      }
      if(md.keywords == 'null' || md.keywords == null || md.keywords === undefined){  //素材关键词
        md.keywords = ''
      }

      this.allowChange == false ?this.allowChange = 0: this.allowChange= 1 //是否允许其他人修改

      // 找出哪个信息不一致
      // console.log(md.name != this.materialName)
      // console.log(md.introduce != this.materialIntro)
      // console.log('dynamicTagsId',dynamicTagsId,this.dynamicTagsId,!compareArraySort(dynamicTagsId,this.dynamicTagsId))
      // console.log(md.keywords != this.materialKeywords.replace(/、/g,","))
      // console.log('md.commercial_licenses',md.commercial_licenses,this.commercialLicenses,md.commercial_licenses != this.commercialLicenses)
      // console.log(md.photographer != this.photographer.replace(/、/g,","))
      // console.log(String(md.view_type) != this.viewTypeValue)
      // console.log('departmentSeeIds',departmentSeeIds,this.departmentSeeIds,!compareArraySort(departmentSeeIds,this.departmentSeeIds))
      // console.log(md.allow_change_if != this.allowChange)

      // 找出哪个信息不一致
      if(md.name != this.materialName || md.introduce != this.materialIntro || !compareArraySort(dynamicTagsId,this.dynamicTagsId) || md.keywords != this.materialKeywords.replace(/、/g,",") || md.commercial_licenses != this.commercialLicenses || md.photographer != this.photographer.replace(/、/g,",") || String(md.view_type) != this.viewTypeValue || !compareArraySort(departmentSeeIds,this.departmentSeeIds) || md.allow_change_if != this.allowChange){
          return true
      }else{
        return false
      }

    }



  },
  beforeRouteLeave(to, from, next) {
    // console.log(to,from,next)
    this.userBehavior= 4
    this.toRoute = to.path
    this.routeLeave?next():''
    // console.log(to)
    this.meterialList.length && this.checkIfEdit()?this.saveMeterialDialog = true:''
    if(!this.saveMeterialDialog && !this.routeLeave){
      next()
      return
    }

  },
  beforeDestroy() {
    //销毁监听事件,由于是单页面应用,不销毁的话监听事件会一直存在
    let scrollContent = this.$refs.middleListContent
    scrollContent.removeEventListener('scroll', this.scrollBottom,true)
  },
  destroyed() {
    this.$store.commit('myMaterialPage', false)
    sessionStorage.removeItem("checkId") //将素材id缓存清除
  }

}
</script>
<style lang="scss" scoped>
@import '~@/assets/css/mixin.scss';
  .material_box{
    @media (max-width: 1366px) and (max-height:768px) {
      height: 100%;
      overflow-y: auto;
    }
  }
  .my_meterial{
    padding: 36px 6.4% 0 3.8%;
    height: 100%;
    @media (max-height:900px) {
     padding: 20px 6.4% 0 3.8%;
    }
    @media (max-width: 1366px) and (max-height:768px) {
      padding-bottom: 40px;
    }
    .opacity{
      opacity:0.6;
　　　filter:alpha(opacity=60);  //filter 过滤器   兼容IE678
    }
    .left_content{
      height: 100%;
      .top_search{
        display: flex;
        align-items: center;
        justify-content: center;
        .el-input-group{
          width: 67.8%;
          /deep/.el-input-group__prepend{
            width: 99px;
            height: 50px;
            background-color: rgba(94, 95, 114, 0.2);
            color: rgba(255,255,255,0.9);
            border: none;
            box-sizing: border-box;
            border-radius: 6px 0px 0px 6px;
            .el-input__inner{
              background-color: rgba(94, 95, 114, 0.2);
              border-radius: 6px 0px 0px 6px;
              padding: 0 21px;
            }
            .el-input__suffix{
                right: 12px;
              .el-select__caret{
                color: rgba(255,255,255,0.9);
              }
            }
            .el-select-dropdown{
              left: 0 !important;
              border: none;
              background-color: #292939;
              margin-top: 1px;
              .el-select-dropdown__item{
                color: rgba(255,255,255,0.9);
                &:hover{
                  // background-color: #1B1B2A !important;
                  color: #409EFF !important;
                }
                &:first-of-type{
                  border-top-left-radius: 5px;
                  border-top-right-radius: 5px;
                }
                &:last-of-type{
                  border-bottom-left-radius: 5px;
                  border-bottom-right-radius: 5px;
                }
              }
              .el-select-dropdown__list{
                padding: 0;
              }
              .el-select-dropdown__item.hover{
                color: rgba(255,255,255,0.9);
                background-color: inherit;
              }
              .el-select-dropdown__item.selected{
                color: #409EFF;
                // background-color: #1B1B2A;
              }
              .popper__arrow{
                display: none;
              }
            }
          }
          /deep/.el-input__inner{
            height: 50px;
            background-color: #1B1B2A;
            border: none;
            color: rgba(255,255,255,0.9);
            &::placeholder{
              color: rgba(255,255,255,0.6);
            }
          }
          /deep/.el-input-group__append{
            border-radius: 0 6px 6px 0;
            background-color: #1B1B2A;
            border: none;
            .el-icon-search{
              font-size: 18px;
              color: rgba(255,255,255,0.9);
            }
          }
        }
      }
      .middle_list{
        border: 1px solid #3E3D4C;
        border-radius: 6px;
        overflow-x: hidden;
        height: 68vh;
        margin-top: 21px;
        @include scrollbar(6px,7px);
        .list{
          // text-align: center;
          .item{
            width: 20.2% !important;
            // text-align: left;
            box-sizing: border-box;
            margin: 0 1.5%;
            margin-top: 30px;
            display: inline-block;
            position: relative;
            &:nth-child(4n+1){
              margin-left: 5%;
            }
            &:nth-child(4n+4){
              margin-right: 5%;
            }
            .outer_material{
              position: relative;
            }
            .hover_effect{
              position: absolute;
              top: 8px;
              right: 7px;
              z-index: 10000;
              display: none;
              .iconfont{
                padding: 2px;
                border-radius: 2px;
                background-color: rgba(0, 0, 0, 0.5);
                display: inline-block;
                margin-right: 3px;
                cursor: pointer;
                font-size: 12px;
              }
              .icon-shoucang{
                color: #ffa200;
              }
            }
            .choose_material{
              display: inline-block;
              width: 22px;
              height: 22px;
              background: url("../assets/images/choose_material.png") no-repeat;
              background-size: 100%;
              position: absolute;
              bottom: 8px;
              right: 12px;
              z-index: 9999;
            }
            .video_duration{
              position: absolute;
              right: 0;
              bottom: 0;
              color:#fff;
              font-size: 12px;
              padding: 4px 6px;
              border-radius: 4px;
              background-color: rgba(0, 0, 0, 0.5);
              z-index: 9
            }
          }
          .title{
            margin-top:12px;
            line-height:20px;
            cursor: pointer;
            color: rgba(255,255,255,0.9);
            &:hover{
              color: #2A9CFF;
            }
          }
        }
        .video_list{
          .video_item{
            .video_preview{
              width: 100% !important;
              height: 110px !important;
              background-color: #000;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
        .img_list{
          // padding-bottom: 40px;
          .img_item{
            .img_outer{
              @include common-item(100%,110px);
              &:hover{
                .hover_effect{
                  display: block;
                }
                .video_duration{
                  display: none;
                }
              }
            }
            .gifNode{
              width: 0;
              height: 0;
            }
            .gifNode_show{
              width: auto;
              height: auto;
            }
            img{
              cursor: pointer;
            }
          }
        }
        .audio_list{
          .audio_item{
            .audio_outer{
              position: relative;
              width: 100%;
              height: 110px;
              background-color: #1B1B2A;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
              img{
                width:72px;
                height: 77px;
              }
              &:hover{
                .hover_effect{
                  display: block;
                }
                .video_duration{
                  display: none;
                }
              }
              .control_icon{
                display: inline-block;
                width: 23px;
                height: 23px;
                position: absolute;
                bottom: 8px;
                left: 8px;
                z-index: 9999;
              }
              .control_play{
                background: url("../assets/images/audio-playing.png") no-repeat;
                background-size: 100%;
              }
              .control_pause{
                background: url("../assets/images/audio-stop.png") no-repeat;
                background-size: 100%;
              }
            }
          }
        }
      }
    }
    .right_detail{
      .title{
        font-size: 18px;
        color: rgba(255,255,255,0.8);
        line-height: 50px;
      }
      .edit_detail{
          margin-top: 18px;
        /deep/.el-input__inner,/deep/.el-textarea__inner{
          background-color: #0F0F1E;
          border: 1px solid #5E5F72;
          border-radius: 6px;
          color: rgba(255,255,255,0.9);
          resize: none;
        }
        .text_area_sty{
          -ms-overflow-style: none;
          /deep/.el-textarea__inner{
            padding: 5px 15px 10px 15px;
            height: 13vh;
            -ms-overflow-style: none;
            @media (max-height:900px) {
              height: 10vh;
            }
            @include scrollbar(3px,5px);
          }
        }
        .intro_sty{
          .el-textarea__inner{
            height: 8vh;
          }
        }
        .ifsee_select{
          width: 100%;
          /deep/.el-select-dropdown{
            margin: 2px 0;
            background: #2d2d3d;
            border: inherit;
            .el-select-dropdown__list{
              padding: 0;
              .el-select-dropdown__item.hover{
                background-color: #3E3D4C;
                color: #409EFF;
                font-weight: 700;
                // background-color: inherit;
              }
            }
            .popper__arrow{
              display: none;
            }
          }

        }
        .operation_btn{
          position: relative;
          .el-button{
            background-color: #2D2C39;
            border: none;
            color: rgba(255,255,255,0.9);
            border-radius: 16px;
            padding: 8px 15px;
          }
          .success_info{
            position: absolute;
            font-size: 12px;
            color: #fff;
            background-color: #2A9CFF;
            padding: 5px 9px;
            border-radius: 2px;
            right: -52px;
            top: -31px;
          }
        }
        .allow_change{
          margin-bottom:5.4vh;
          @media (max-height:900px) {
            margin-bottom:2.4vh;
          }
        }
        .tag_box{
          box-sizing: border-box;
          width: 100%;
          height: 13.7vh;
          padding: 10px 0 18px 15px;
          border: 1px solid #5E5F72;
          border-radius: 6px;
          cursor: text;
          @media (max-height:900px) {
              height: 11vh;
          }
          .el-tag{
            background-color: #3E3D4C;
            border-radius: 6px;
            color: rgba(255,255,255,0.9);
            border: none;
            margin-right: 10px;
            margin-bottom: 10px;
            /deep/.el-tag__close{
              color: rgba(255,255,255,0.9);
              &:hover{
                background-color: inherit;
                color: #409eff;
              }
            }
          }
        -ms-overflow-style: none;
        @include scrollbar(3px,5px);
        }
      }
    }
    .bottom_des{
      font-size: 14px;
      color: rgba(255,255,255,0.6);
      margin-left: 51px;
      margin-top: 28px;
    }
  }
  /deep/.el-loading-spinner .circular{
    width: 36px;
    height: 36px;
  }
  /deep/.el-loading-spinner .path{
    stroke: #fff;
    stroke-width: 3;
  }
  /deep/.el-loading-spinner .el-loading-text{
    color: #fff;
    margin-top: 10px;
  }
  @import url('../assets/css/list_video.scss');
</style>

<style lang="scss">
@import '~@/assets/css/mixin.scss';
  .my_material_tooltip.el-tooltip__popper{
    padding: 6px 10px;
    // max-width: 10%;
    // line-height: 18px;
  }
  .el-autocomplete-suggestion{display:none;}
  .search-autocpmplete{
    border: 1px solid #5E5F72;
    border-radius: 6px;
    margin-top: 10px !important;
    z-index: 99999999 !important;
    .el-scrollbar__bar.is-vertical{
      background-color: #5E5D6E;
      }
    .el-scrollbar__thumb{
      background-color: #B9BAC9;
    }
  }
  .search-autocpmplete li.highlighted, .search-autocpmplete li:hover{
    background-color: inherit;
  }
  .search-autocpmplete .el-scrollbar{
    background-color: #0F0F1E;
    border-radius: 5px;
    li{
      padding-left: 10px;
    }
    .search_tag{
      background-color: #3E3D4C;
      color: rgba(255,255,255,0.9);
      display: inline-block;
      padding: 5px 11px;
      font-size: 14px;
      line-height: 14px;
      border-radius: 6px;
    }
  }
  .search-autocpmplete .popper__arrow{
    display: none;
  }
  .el-dialog.material-dialog{
      background-color: #292939;
      border-radius: 15px;
      box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.16);
      padding: 8px 21px 21px;
      .el-dialog__header{
        text-align: center;
        padding: 0;
        padding-bottom: 6px;
        .el-dialog__title{
          color: #fff;
          font-size: 16px;
          letter-spacing: 30px;
        }
        .el-dialog__headerbtn{
          top: 10px;
          .el-dialog__close{
            color: #fff;
            font-size: 18px;
          }
        }
      }
      .el-dialog__body{
        color: #fff;
        background-color: #0F0F1E;
        font-size: 14px;
        padding: 38px 69px 0 69px;
        .radio_check{
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          .el-radio{
            width: 50%;
            margin-right: 0;
            margin-bottom: 18px;
            color: #fff;
          }
          .disable_radio{
            .el-radio__inner{
              background-color: rgba(255,255,255,0.5);
              border: inherit;
            }
            .el-radio__label{
              color: rgba(255,255,255,0.5);
            }
          }
          .el-radio__inner{
            width: 16px;
            height: 16px;
          }
          .is-checked{
            .el-radio__inner{
              width: 16px;
              height: 16px;
              border: 3px solid #fff;
              &::after{
                background-color: inherit;
              }
            }
          }
        }
      }
      .el-dialog__footer{
        padding: 20px 69px 45px 69px;
        background-color: #0F0F1E;;
        border-radius: 0px 0px 10px 10px;
        .dialog-footer{
          display: flex;
          justify-content: space-between;
          .el-button{
            border-radius: 20px;
            border: 1px solid #5E5F72;
            background-color: #0F0F1E;;
            color: rgba(255,255,255,0.9);
            padding: 6px 15px;
          }
        }
      }
  }
  .el-dialog.permission-dialog{
      background-color: #292939;
      border-radius: 15px;
      box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.16);
      padding: 8px 21px 21px;
      .el-dialog__header{
        text-align: center;
        padding: 0;
        padding-bottom: 6px;
        .el-dialog__title{
          color: rgba(255,255,255,0.9);
          font-size: 16px;
          letter-spacing: 3px;
        }
        .el-dialog__headerbtn{
          top: 10px;
          .el-dialog__close{
            color: #fff;
            font-size: 18px;
          }
        }
      }
      .el-dialog__body{
        color: rgba(255,255,255,0.9);
        background-color: #0F0F1E;
        font-size: 14px;
        padding: 38px 62px 0 62px;
        .search_tree{
          .el-input{
            .el-input__inner{
              background-color: #0F0F1E;
              border: 1px solid #5E5F72;
              border-radius: 5px;
              color: rgba(255,255,255,0.9);
            }
            .el-input__icon{
              &::before{
                font-size: 18px;
                font-weight: bold;
                color: #5E5F72;
              }
            }
          }
          .el-tree{
            background-color: #0F0F1E;
            border: 1px solid #5E5F72;
            margin-top: 15px;
            height: 220px;
            -ms-overflow-style:none;
            @include scrollbar(5px,7px);

            .el-tree-node{
              &:focus>.el-tree-node__content {
                background-color: inherit !important;
              }
              .el-tree-node__content{
                &:hover{
                   background-color: inherit;
                }
                .el-checkbox{
                  width: auto;
                  // margin-right: 11px;
                }
                .el-tree-node__label{
                  margin-left: 11px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  width: 70%;
                  white-space: nowrap;
                  color: rgba(255,255,255,0.8);
                }
                &:hover{
                  background-color: #3E3D4C;
                  .el-tree-node__label{
                    // color: #409EFF;
                  }
                }
              }
              &.is-current{
                .el-tree-node__content{
                  background-color: inherit;
                }
              }
            }
          }
        }
      }
      .el-dialog__footer{
        padding: 20px 62px 36px 62px;
        background-color: #0F0F1E;
        border-radius: 0px 0px 10px 10px;
        .dialog-footer{
          display: flex;
          justify-content: space-between;
          .el-button{
            border-radius: 20px;
            border: 1px solid #5E5F72;
            background-color: #0F0F1E;
            color: rgba(255,255,255,0.9);
            padding: 6px 15px;
          }
        }
      }
  }
  .el-dialog.edit-dialog{
      background-color: #292939;
      border-radius: 15px;
      box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.16);
      padding: 8px 21px 21px;
      .el-dialog__header{
        text-align: center;
        padding: 0;
        padding-bottom: 6px;
        .el-dialog__title{
          color: rgba(255,255,255,0.9);
          font-size: 16px;
          letter-spacing: 3px;
        }
        .el-dialog__headerbtn{
           top: 10px;
          .el-dialog__close{
            color: #fff;
            font-size: 18px;
          }
        }
      }
      .el-dialog__body{
        box-sizing: border-box;
        color: rgba(255,255,255,0.9);
        background-color: #0F0F1E;
        font-size: 14px;
        padding: 38px 62px 0 62px;
        height: 384px;
        .search_tag{
          // text-align: center;
          .el-autocomplete{
            width: 218px;
          }
          .top_search{
            .edit_btn{
              display: inline-block;
              color: rgba(255,255,255,0.9);
              background-color: #3E3D4C;
              padding: 5px 11px;
              border-radius: 6px;
              margin-left: 12px;
              cursor: pointer;
            }

          }
          .lately_tag{
            text-align: left;
            margin-top: 67px;
            .title{
              margin-bottom: 10px;
            }
          }
          .el-input{
            .el-input__inner{
              background-color: #0F0F1E;
              border: 1px solid #5E5F72;
              border-radius: 5px;
              color: rgba(255,255,255,0.9);
            }
            .el-input__icon{
              &::before{
                font-size: 18px;
                font-weight: bold;
                color: #fff;
              }
            }
          }
        }
        .el-tag{
            background-color: #3E3D4C;
            border-radius: 6px;
            color: rgba(255,255,255,0.9);
            border: none;
            margin-right: 10px;
            margin-bottom: 10px;
            cursor: pointer;
        }
      }

  }
</style>
